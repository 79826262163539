import React from "react";
import Link from 'gatsby-link';
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text, Span } from "../components/Core";
import Head from "../components/head";
import '../components/hero.scss';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/about-d.jpg";
import styled from "styled-components";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="10px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={5}
      minWidth={5}
      borderRadius="50%"
      color={color}
    
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background: url(${iconName})  ;
        background-size:cover;
      `}
      mr={3}
    >
      
    </Box>

    <Title variant="card" mb={0}>
      {title}
    </Title>
  </Box>
);

const AreasPage = () => (
  <>
    
    <Head title="Non Surgical Treatments Areas We Serve"  description="Non Surgical Nose jobs London Book Online" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
   

    <HeroComponent
    itext='<span style="color: #1a1a1a;">Non Surgical Treatments </span> <span style="color: #00aec7;">Areas We Serve</span>'
    secondText="Find the right treatment for you"
    videoAlt="Non surgical treatments London"
    imgVideo={imgVideo} // Specify your image URL here
  />
 
<Separator />
    <Section py={4}   bg="#f7f7fb" >
      <Container lg="8" className="pt-3 pb-3" data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50">
    
       <Row>
           <Col lg="12"  className="">

               <Text><b>London</b></Text> <br />
               <Link to="/non-surgical-nose-job"><Text>Non Surgical Nose Job</Text></Link>
               <Link to="/liquid-nose-job"><Text>Liquid Nose Job</Text></Link>
               <Link to="/nose-filler"><Text>Nose Filler</Text></Link>
               <Link to="/non-surgical-rhinoplasty"><Text>Non Surgical Rhinoplasty</Text></Link>
               <Link to="/prp-for-hair-loss"><Text>PRP For Hair Loss</Text></Link>
               <Link to="/prp-hair-restoration"><Text>PRP Hair Restoration</Text></Link>
               <Link to="/hair-restoration-london"><Text>Non Surgical Hair Loss Treatment</Text></Link>
               <Link to="/advanced-facial-treatments-london"><Text>Advanced Facials</Text></Link>
               <Link to="/prp-face-lift"><Text>PRP Face Lift</Text></Link>
               <Link to="/hydrafacial"><Text>HydraFacial</Text></Link>
               <Link to="/mesotherapy"><Text>Mesotherapy</Text></Link>
               <Link to="/chin-filler"><Text>Chin Filler</Text></Link>
               <Link to="/jaw-filler"><Text>Jaw Filler</Text></Link>
               
                        <Link to="/filler-packages"><Text>Filler Packages</Text>                      </Link>
                      
                       
                        
                        <Link to="/lip-filler">
                        <Text>Lip Filler</Text>                        </Link>
                        


                        
                        <Link to="/chin-jaw">
                        <Text>Chin / Jaw Filler</Text>                    </Link>
                       


                        
                        <Link to="/tear-trough-under-eye">
                        <Text>Dark Circles Under Eye</Text>              </Link>
                        

                       
                        <Link to="/smile-line">
                        <Text>Smile Lines</Text>                      </Link>
                      

                      
                        <Link to="/corner-of-mouth">
                        <Text>Corner Of Mouth</Text>                       </Link>



                      
                        <Link to="/prp-beard-restoration-treatment">
                        <Text>Beard Restoration</Text>                         </Link>
                        
                       
                       
                        <Link to="/anti-wrinkle-treatments">
                        <Text>Anti-Wrinkle</Text>                    </Link>
                       


                       
                        <Link to="/laser-hair-removal">
                        <Text>Laser hair removal</Text>                      </Link>
                       

                        
                        <Link to="/gummy-smile">
                        <Text>Gummy Smile</Text>                       </Link>
                       


                      
                        <Link to="/eyebrow-lift">
                        <Text>Eyebrow Lift</Text>                       </Link>
                      

                        
                        <Link to="/masseter">
                        <Text>Masseter</Text>                      </Link>
                       
                       
      


           </Col>
           </Row>

      </Container>
    </Section>
<SeparatorEnd />
    <Clinic /> 
<Saveface />
<Trend />
       
  </>
);

export default AreasPage;
